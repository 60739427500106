<template>
    <div v-if="scriptIsReady" :id="adsId" class="mb-2" />
</template>

<script>
export default {
    props: ['ads_data'],

    computed: {
        adsId() {
            const randomString = (Math.random() + 1).toString(36).substring(7)
            return `ads-html-${randomString}`
        },
        script() {
            return new DOMParser().parseFromString(this.ads_data.html_code, 'text/html').scripts[0]
        },
        scriptIsReady() {
            return this.script
                && this.script.src.length > 5
                && this.script.charset.length > 3
        }
    },

    mounted() {
        this.initScriptTag()
    },

    methods: {
        initScriptTag() {
            if (!this.scriptIsReady) return

            const mainDiv = document.getElementById(this.adsId)
            const adsScript = document.createElement("script")

            adsScript.setAttribute(
                'src', this.script.src,
                'charset', this.script.charset,
                'async', this.script.async,
            )

            mainDiv.appendChild(adsScript)
        }
    }
}
</script>
